<template lang="pug">
    div
        
        .animate__animated.animate__fadeIn.flex.flex-wrap.mx-auto.mb-16(v-show='opening === 1' class='md:w-1/3')
            LottieAnimation(path='lottie/present.json' @AnimControl='animController')
            img.max-w-xs.mx-auto.my-8.w-full.h-full(src="@/assets/img/vantagens-logo.png")
        
        .animate__animated.animate__fadeIn.bg-gray-200(style='min-height:60vh' v-if='opening === 2')

            .max-w-screen-lg.w-full.mx-auto

                .p-2(v-if='verDetalhes')

                    a.inline-block.my-1(href='javascript:;' @click='back()')
                        b-icon.text-3xl(pack='jam' icon='jam-chevron-left')

                    .bg-white.shadow-lg
                        .mx-auto.p-2(class='w-2/3')
                            img.w-full.mx-auto(class='md:w-1/3' :src="verDetalhes.aq_foto")
                        .p-4.border-t
                            h1.font-bold.my-4 {{ verDetalhes.nm_fantasia }}
                            .whitespace-pre-wrap.text-black.text-justify.text-sm(v-html='verDetalhes.ds_mais_informacoes')
                            h1.mt-4.text-sm.font-bold.text-blue-600(v-if='verDetalhes.nr_telefone') TELEFONE
                            .flex.text-black.text-justify.items-center(v-if='verDetalhes.nr_telefone')
                                b-icon.mr-1(icon='phone')
                                p.text-sm {{ verDetalhes.nr_telefone }}
                            h1.mt-4.text-sm.font-bold.text-blue-600(v-if="verDetalhes.nm_rua") ENDEREÇO
                            .flex.text-black.text-justify(v-if="verDetalhes.nm_rua")
                                b-icon.mr-1(icon='map-marker')
                                p.text-black.text-justify.text-sm {{ verDetalhes.nm_rua }}, {{ verDetalhes.nr_numero }}<br/>{{ verDetalhes.nm_bairro }}<br/>{{ verDetalhes.nr_cep }}
            
                .p-2(v-else)
                    .flex.bg-white.items-center.shadow-lg.mt-4
                        .p-2(class='w-1/4')
                            img.mx-auto.w-full(class='md:w-1/2' src="@/assets/img/vantagens-graphic.png")
                        .p-4(class='w-3/4')
                            h1.font-bold.text-blue-600.text-sm O que é o Medclub Vantagens?
                            p.text-black.text-justify.text-sm Uma vitrine de ofertas e descontos exclusivos para clientes medclub. Para fazer parte do nosso Clube de Vantagens e ter acesso a todos os benefícios é muito simples, basta fazer seu cadastro gratuito no nosso aplicativo ou site e depois é só aproveitar os benefícios.

                    .my-4
                        p.font-bold Parceiros Medclub

                    div(v-for='parceiro, idx in parceiros' :key='idx')
                        .flex.bg-white.items-center.shadow-lg.mb-4.cursor-pointer(@click='verParceiro(parceiro)')
                            .p-2(class='w-3/12')
                                img.w-full.mx-auto(class='md:w-1/2' :src="parceiro.aq_foto")
                            .p-4(class='w-8/12')
                                h1.font-bold.text-sm {{ parceiro.nm_fantasia }}
                                p.text-black.text-sm {{ parceiro.ds_informacoes }}
                                p.text-blue-700.font-bold.text-sm {{ parceiro.ds_msg_label }}
                            div(class='w-1/12')
                                b-icon.text-4xl(pack='jam' icon='jam-chevron-right')
</template>

<script>
    import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
    import { Utils } from '../../middleware'
    export default {
        components: { LottieAnimation },
        async created () {
            window.setTimeout(() => {
                this.opening = 1
            }, 250)
            const response = await Utils.getParceiros()
            if (response.status === 200) {
                this.parceiros = response.data

                if (this.$route.query.parceiro) {
                    let p = this.parceiros.find(p => p.id == this.$route.query.parceiro)
                    if (p) this.verDetalhes = p
                }
            }
        },
        data () {
            return {
                opening: 0,
                parceiros: [],
                verDetalhes: null
            }
        },
        methods: {
            animController (anim) {
                anim.onEnterFrame = (ev) => {
                    if (ev.currentTime > 55) {
                        anim.destroy()
                        this.opening = 2
                    }
                }
            },
            verParceiro(parceiro) {
                this.verDetalhes = parceiro
                if (this.$route.query.parceiro != this.verDetalhes?.id) this.$router.replace( { query: { parceiro: parceiro.id } } )
            },
            back() {
                this.verDetalhes = null
                this.$router.replace({query: {}})
            }
        }
    }
</script>